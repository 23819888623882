import React, { useState } from 'react';

export default function CalendarPage(){
    const today = new Date();
    const [currentDate, setCurrentDate] = useState(today);
  
    // Получаем первое и последнее число месяца
    const getDaysInMonth = () => {
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth();
      const firstDayOfMonth = new Date(year, month, 1);
      const lastDayOfMonth = new Date(year, month + 1, 0);
      const daysInMonth = [];
  
      // Добавляем пустые ячейки до первого дня месяца
      for (let i = 0; i < firstDayOfMonth.getDay() - 1; i++) {
        daysInMonth.push(null);
      }
  
      // Добавляем все дни текущего месяца
      for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
        daysInMonth.push(i);
      }
  
      return daysInMonth;
    };
  
    // Переключение месяцев
    const handlePrevMonth = () => {
      setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
    };
  
    const handleNextMonth = () => {
      setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
    };
  
    // Массив дней недели
    const weekdays = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];





    return (
        <div className="calendar-container max-w-full mx-auto p-4">
        <iframe src="https://calendar.yandex.ru/embed/week?&layer_ids=31107277&tz_id=Europe/Moscow&layer_names=public" 
                width="100%"
                height="600"
                frameBorder="0"
                title="Yandex Calendar"
                className="shadow-lg">
        </iframe>
    </div>
    )
}